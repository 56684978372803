import { Box, Container } from "@mui/material";

import Typography from "@mui/material/Typography";
import React from "react";

const ConfidentialityCondition = () => {
  return (
    <Box
      id="features"
      sx={{
        py: 4,
        mt: 6,
        background: "#EDE4C7",
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="h4">
          Conditions de Confidentialité de concours-equestre.fr par VelvetHoof
        </Typography>
        <Typography variant="body" component="div" sx={{ mt: 2 }}>
          Dernière mise à jour : <b>27 avril 2024</b>
        </Typography>
        <Typography variant="body" sx={{ pt: 2 }} component="div">
          Nous, concours-equestre.fr, attachons une grande importance à la
          confidentialité de nos utilisateurs. Cette politique de
          confidentialité décrit les types d'informations personnelles
          collectées et utilisées par notre site web, ainsi que la manière dont
          nous les utilisons.
        </Typography>
        <Typography variant="h6" component="div" sx={{ mt: 2 }}>
          1. Données Personnelles
        </Typography>
        <Typography variant="body" component="div">
          Nous ne collectons aucune information personnelle identifiable sur les
          visiteurs de notre site, à moins que cela ne soit fourni
          volontairement par l'utilisateur, par exemple via un formulaire de
          contact ou d'inscription à une newsletter. Les informations que vous
          nous fournissez seront utilisées uniquement dans le but précis pour
          lequel elles ont été fournies, tel que communiquer avec vous ou vous
          envoyer des mises à jour sur nos services.
        </Typography>
        <Typography variant="h6" component="div" sx={{ mt: 2 }}>
          2. Cookies
        </Typography>
        <Typography variant="body" sx={{ pt: 2 }} component="div">
          Nous utilisons des cookies pour améliorer l'expérience de navigation
          de nos utilisateurs et pour recueillir des informations sur la manière
          dont notre site est utilisé. Ces cookies peuvent être désactivés ou
          supprimés à tout moment en modifiant les paramètres de votre
          navigateur ou en utilisant les options de gestion des cookies
          disponibles sur notre site.
        </Typography>
        <Typography variant="h6" component="div" sx={{ mt: 2 }}>
          3. Services Tiers
        </Typography>
        <Typography variant="body" sx={{ pt: 2 }} component="div">
          Nous utilisons les services de Hotjar et Google Tag Manager (GTM) pour
          comprendre comment nos utilisateurs interagissent avec notre site web
          afin d'améliorer leur expérience. Ces services peuvent collecter des
          informations non personnelles telles que le comportement de navigation
          et les clics de souris. Veuillez noter que ces services peuvent être
          désactivés en utilisant les options de gestion des cookies disponibles
          sur notre site.
        </Typography>
        <Typography variant="h6" component="div" sx={{ mt: 2 }}>
          4. Partage des Informations
        </Typography>
        <Typography variant="body" sx={{ pt: 2 }} component="div">
          Nous ne vendons, n'échangeons ni ne louons aucune information
          personnelle à des tiers à des fins commerciales. Cependant, nous
          pouvons partager des informations avec des tiers de confiance qui nous
          aident à exploiter notre site web ou à mener nos activités, à
          condition que ces parties acceptent de maintenir la confidentialité de
          ces informations.
        </Typography>
        <Typography variant="h6" component="div" sx={{ mt: 2 }}>
          5. Consentement
        </Typography>
        <Typography variant="body" component="div">
          En utilisant notre site web, vous consentez à notre politique de
          confidentialité et à nos conditions d'utilisation.
        </Typography>
        <Typography variant="h6" component="div" sx={{ mt: 2 }}>
          6. Changements à Notre Politique de Confidentialité
        </Typography>
        <Typography variant="body" component="div">
          Nous nous réservons le droit de modifier cette politique de
          confidentialité à tout moment. Les modifications seront publiées sur
          cette page.
        </Typography>
        <Typography variant="h6" component="div" sx={{ mt: 2 }}>
          7. Contact
        </Typography>
        <Typography variant="body" sx={{ pt: 2 }} component="div">
          Si vous avez des questions concernant notre politique de
          confidentialité, vous pouvez nous contacter à l'adresse suivante :{" "}
          <a href="mailto:contact@concours-equestre.fr">
            contact@concours-equestre.fr
          </a>
        </Typography>
      </Container>
    </Box>
  );
};

export default ConfidentialityCondition;
