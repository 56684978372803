import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";

export default function Subscribe() {
  const [snackBarMessage, setSnackBarMessage] = useState(null);
  const navigate = useNavigate();

  const isSnackBarOpen = useMemo(() => !!snackBarMessage, [snackBarMessage]);

  const handleOnboardingClick = useCallback(
    (e) => {
      e.preventDefault();
      navigate("/onboarding");
    },
    [navigate]
  );

  useEffect(() => {
    if (isSnackBarOpen) {
      setTimeout(() => {
        setSnackBarMessage(null);
      }, 4000);
    }
  }, [isSnackBarOpen]);

  return (
    <Box
      id="subscribe"
      sx={{
        background: "#EDE4C7",
        py: 4,
      }}
    >
      <Container maxWidth="lg">
        <Stack>
          <Typography variant="h4">Souscrire</Typography>
          <Typography severity="warning" sx={{ fontSize: "1rem", my: 2 }}>
            Vous êtes directeur d'un poney club ou d'un centre équestre ? Ce
            service vous intéresse ?
            <br />
            <br />
            Créer l'espace de votre club sur concours-equestre.fr et profitez
            d'une gestion simplifiée de vos concours. Le service est gratuit, le
            meilleur moyen de se faire une idée est de l'essayer !
          </Typography>

          <Button
            variant="contained"
            onClick={handleOnboardingClick}
            sx={{ width: "200px", alignSelf: "center", mb: 2 }}
          >
            C'est parti !
          </Button>
        </Stack>
      </Container>
    </Box>
  );
}
