import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const Unsubscribe = () => {
  const [footerHeight, setFooterHeight] = useState(0);
  const [step, setStep] = useState(0);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const handleResize = () => {
      setFooterHeight(document.getElementById("footer").clientHeight);
    };

    window.addEventListener("resize", handleResize);

    setFooterHeight(document.getElementById("footer").clientHeight);
    setTimeout(() => {
      setFooterHeight(document.getElementById("footer").clientHeight);
    }, 100);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const emailId = searchParams.get("emailId");
    if (!emailId || emailId === "") {
      setStep(-1);
    }

    const unsubscribe = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/statistics/email/unsubscribe?emailId=${emailId}`,
        {
          method: "GET",
        }
      );
      if (response.status !== 200) {
        setStep(-1);
      } else {
        setStep(1);
      }
    };

    unsubscribe();
  }, [searchParams]);

  const handleClickHome = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <Box
      id="unsubscribe"
      sx={{
        py: 4,
        mt: 0,
        background: "#EDE4C7",
        minHeight: `calc(100vh - ${footerHeight}px + 16px)`,
        display: "flex",
      }}
    >
      <Container
        sx={{
          maxWidth: "800px !important",
          mt: 8,
        }}
      >
        <Card>
          <CardHeader
            title="Prise en compte de votre désinscription"
            sx={{ textAlign: "center" }}
          />
          <CardContent sx={{ textAlign: "center" }}>
            {step === 0 ? (
              <Typography>Veuillez patienter...</Typography>
            ) : step === -1 ? (
              <Typography>
                Une erreur est survenue, nous n'avons pas pu vous désinscrire.
                <br />
                Veuillez réessayer ultérieurement.
              </Typography>
            ) : (
              <Typography>
                Nous avons pris en compte votre demande de désinscription.
                <br />
                Vous ne recevrez plus d'e-mail de notre part.
              </Typography>
            )}
          </CardContent>
          <CardActions sx={{ alignItems: "center" }}>
            <Button
              variant="contained"
              sx={{ m: "auto" }}
              onClick={handleClickHome}
            >
              Retour à l'accueil
            </Button>
          </CardActions>
        </Card>
      </Container>
    </Box>
  );
};

export default Unsubscribe;
