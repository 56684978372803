import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import SavingsIcon from "@mui/icons-material/Savings";

export default function Prices() {
  return (
    <Box id="price" sx={{ backgroundColor: "#fff", py: 4 }}>
      <Container maxWidth="lg">
        <Stack>
          <Typography variant="h4" sx={{ color: "#000" }}>
            Tarifs
          </Typography>
          <Grid container spacing={4} sx={{ pt: 2 }}>
            <Grid item xs={12} sm={12} md={6} lg={9}>
              <Typography sx={{ fontSize: "1rem" }}>
                Il n'y a pas de frais d'inscription, ni de frais cachés.
                <br />
                Les frais sont liés aux concours que vous organisez.
                <br />
                <br />
                Il existe deux options, et c'est vous qui choisissez lors de la
                création de votre concours :
                <ul>
                  <li>
                    <b>Paiement en ligne pour les cavaliers</b>
                    <br />
                    1% de frais de service, facturés au cavalier.
                    <br />
                    1,5% + 0,25€ de frais de traitement, facturés au club *.
                    <br />
                    <Typography
                      sx={{
                        fontSize: ".7rem",
                        mb: 2,
                      }}
                    >
                      * Ces frais sont liés à l'utilisation de la solution de
                      paiement en ligne Stripe. Ils peuvent varier en fonction
                      du type de carte bancaire utilisée.
                    </Typography>
                  </li>
                  <li>
                    <b>
                      Paiement hors ligne pour les cavaliers, facturation
                      directe par le club
                    </b>
                    <br />
                    2,25€ par concours, facturés au club.
                    <br />
                    <Typography
                      sx={{ fontSize: ".9rem", fontWeight: "bold" }}
                      color="#302818"
                    >
                      🎁 Les 3 premiers concours sont gratuits pour vous
                      permettre de tester la solution !
                    </Typography>
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <Card
                sx={{ height: "100%", backgroundColor: "#302818" }}
                variant="outlined"
              >
                <CardHeader
                  title="Abonnement"
                  titleTypographyProps={{ variant: "h6" }}
                  avatar={<SavingsIcon />}
                  sx={{ color: "#fff" }}
                ></CardHeader>
                <CardContent sx={{ textAlign: "center" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "inline-block",
                      fontSize: "5rem",
                    }}
                  >
                    0
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "inline-block",
                      fontSize: "2.5rem",
                    }}
                  >
                    €/mois
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: "#000",
                  fontSize: "1rem",
                  fontStyle: "italic",
                  textAlign: "center",
                }}
              >
                <b>
                  0€ d'abonnement, 0€ de frais d'inscription, 0€ de frais
                  cachés.
                </b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: "#000",
                  fontSize: ".7rem",
                  textAlign: "center",
                }}
              >
                Nous créons gratuitement pour vous un sous domaine de
                concours-equestre.fr, par exemple monclub.concours-equestre.fr.
                <br />
                Vous pouvez également utiliser votre propre nom de domaine, dans
                ce cas, des frais de 15€ vous seront facturés pour la mise en
                place.
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}
