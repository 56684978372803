import { Box, Container } from "@mui/material";

import Typography from "@mui/material/Typography";
import React from "react";

const ServiceCondition = ({ mt, background }) => {
  return (
    <Box
      id="confidentiality-conditions"
      sx={{
        py: 4,
        mt: mt ? mt : 0,
        background: background ? background : "#EDE4C7",
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="h4">
          Conditions de Service de concours-equestre.fr par VelvetHoof
        </Typography>
        <Typography variant="body" component="div" sx={{ mt: 2 }}>
          Dernière mise à jour : <b>27 avril 2024</b>
        </Typography>
        <Typography variant="body" sx={{ pt: 2 }} component="div">
          Bienvenue sur concours-equestre.fr, un service fourni par VelvetHoof.
          Veuillez lire attentivement les conditions suivantes avant d'utiliser
          notre site. En accédant ou en utilisant concours-equestre.fr, vous
          acceptez d'être lié par ces conditions.
        </Typography>
        <Typography variant="h6" component="div" sx={{ mt: 2 }}>
          1. Utilisation du Service
        </Typography>
        <Typography variant="body" component="div">
          <b>1.1 Création de Concours :</b> Le premier concours doit être créé
          dans les deux mois suivant la création du site pour le club. De plus,
          le club est tenu de créer un concours au moins tous les 4 mois pour
          maintenir l'accès au site. La non-conformité à cette condition peut
          entraîner la suppression de l'accès au site.
        </Typography>
        <Typography variant="body" sx={{ pt: 2 }} component="div">
          <b>1.2 Frais sur les paiements en ligne :</b> L'utilisation du service
          est gratuite. Une commission de 1% est ajoutée au montant des
          inscriptions pour les paiements en ligne, à la charge du cavalier.
          <br />
          Les paiements en ligne sont traités par Stripe. Des frais de
          traitement banquaire sont prélevés sur les paiements. Ces frais sont à
          la charge du club, ils varient en fonction du type de carte bancaire
          utilisée (
          <a href="https://stripe.com/pricing" target="_blank" rel="noreferrer">
            Tarifs Stripe
          </a>
          ).
        </Typography>
        <Typography variant="body" sx={{ pt: 2 }} component="div">
          <b>1.3 Frais sur les concours sans paiement en ligne :</b> Les
          concours sans paiement en ligne sont facturés 2,25€ par concours. Le
          paiement se fait en ligne par carte bancaire ou par virement bancaire
          SEPA.
          <br />
          Le club est responsable de la collecte des paiements pour ces concours
          ainsi que du remboursement des cavaliers en cas d'annulation.
        </Typography>
        <Typography variant="body" sx={{ pt: 2 }} component="div">
          <b>1.4 Frais sur les concours annulés :</b> Les concours annulés sont
          automatiquement remboursés aux cavaliers. Les frais de traitement
          bancaire sont à la charge du club.
          <br />
          Le club est responsable de disposer des fonds nécessaires pour
          rembourser les cavaliers sur son compte Stripe. Le cas échéant, le
          compte Stripe doit être approvisionné pour couvrir les remboursements.
          <br />
          VelvetHoof n'est pas responsable en cas de défaut de paiement du club.
        </Typography>
        <Typography variant="h6" component="div" sx={{ mt: 2 }}>
          2. Responsabilités du Club
        </Typography>
        <Typography variant="body" component="div">
          <b>2.1 Gestion du Site :</b> Le club est responsable de la gestion de
          son espace sur le site, y compris la gestion des utilisateurs et des
          concours (création, annulation, etc.).
        </Typography>
        <Typography variant="h6" component="div" sx={{ mt: 2 }}>
          3. Propriété et Gestion du Site
        </Typography>
        <Typography variant="body" component="div">
          <b>3.1 Société Gérante :</b> Le service concours-equestre.fr est géré
          par VelvetHoof. Toutes les questions, préoccupations ou demandes de
          support doivent être adressées à VelvetHoof.
        </Typography>
        <Typography variant="body" sx={{ pt: 2 }} component="div">
          <b>3.2 Modification des Conditions :</b> VelvetHoof se réserve le
          droit de modifier les présentes conditions de service à tout moment.
          Les utilisateurs seront informés des modifications par le biais de
          notifications sur le site ou par d'autres moyens appropriés. En
          continuant à utiliser le service après toute modification, vous
          acceptez les nouvelles conditions.
        </Typography>
        <Typography variant="h6" component="div" sx={{ mt: 2 }}>
          4. Confidentialité
        </Typography>
        <Typography variant="body" component="div">
          <b>4.1 Données Utilisateur :</b> VelvetHoof s'engage à protéger la
          confidentialité des informations fournies par les utilisateurs
          conformément à sa politique de confidentialité.
        </Typography>
        <Typography variant="h6" component="div" sx={{ mt: 2 }}>
          5. Résiliation
        </Typography>
        <Typography variant="body" component="div">
          <b>5.1 Résiliation par le Club :</b> Le club peut résilier son compte
          à tout moment en notifiant VelvetHoof.
        </Typography>
        <Typography variant="body" sx={{ pt: 2 }} component="div">
          <b>5.2 Résiliation par VelvetHoof :</b> VelvetHoof se réserve le droit
          de résilier ou suspendre l'accès au service, en tout ou en partie, en
          cas de non-respect des présentes conditions par le club.
        </Typography>
        <Typography variant="body" sx={{ mt: 2 }} component="div">
          En utilisant concours-equestre.fr, vous acceptez ces conditions de
          service. Si vous n'acceptez pas ces conditions, veuillez ne pas
          utiliser le service.
        </Typography>
        <Typography variant="body" sx={{ mt: 2 }} component="div">
          Pour toute question concernant ces conditions, veuillez nous contacter
          à{" "}
          <a href="mailto:contact@concours-equestre.fr">
            contact@concours-equestre.fr
          </a>
          .
        </Typography>
        <Typography variant="body" sx={{ mt: 2 }} component="div">
          Merci de choisir concours-equestre.fr par VelvetHoof !
        </Typography>
      </Container>
    </Box>
  );
};

export default ServiceCondition;
