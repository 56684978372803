import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";

import ServiceCondition from "../../ServiceCondition/ServiceCondition";

const StepServiceCondition = ({ onStepValidated }) => {
  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
    setAccepted(!accepted);
  };

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      onStepValidated({
        Accepted: accepted,
      });
    },
    [accepted, onStepValidated]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
        Conditions d'utilisation
      </Typography>

      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{ mt: 2, mx: 4, width: "90%" }}
      >
        <Typography component="p" variant="body1" sx={{ mt: 2 }}>
          Veuillez lire et accepter les conditions d'utilisation.
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
            overflow: "scroll",
            maxHeight: "40vh",
          }}
        >
          <ServiceCondition background="#FFF" />
        </Box>
        {/* Checkbox to accept the conditions using mui */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <FormControl required component="fieldset" error={!accepted}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={accepted}
                  onChange={handleAccept}
                  name="accept"
                />
              }
              label="J'accepte les conditions d'utilisation."
            />
          </FormControl>
        </Box>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2 }}
          disabled={!accepted}
        >
          Suivant
        </Button>
      </Box>
    </Box>
  );
};

export default StepServiceCondition;
