import {
  AppBar,
  Box,
  Container,
  IconButton,
  Menu,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";

import AnchorButton from "./AnchorButton";
import AnchorMenuItem from "./AnchorMenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import ScrollToColor from "./ScrollToColor";
import logo from "../../assets/logo.png";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Header() {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const matches = useMediaQuery("(min-width:600px)");
  console.log(matches);

  const handleHome = useCallback(() => {
    window.location.href = "/";
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = useCallback(() => {
    setAnchorElNav(null);
  }, []);

  const handleLinkClick = useCallback(
    (link) => {
      handleCloseNavMenu();
    },
    [handleCloseNavMenu]
  );

  const fontFamily = useMemo(() => {
    // if safari : luminary, else : fantasy
    return window.navigator.userAgent.indexOf("Safari") !== -1 &&
      window.navigator.userAgent.indexOf("Chrome") === -1
      ? "Luminari"
      : "fantasy";
  }, []);

  //#EDE4C7
  return (
    <ScrollToColor threshold={30}>
      <AppBar
        position="fixed"
        elevation={6}
        sx={{ backgroundColor: "transparent" }}
      >
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            <img
              src={logo}
              onClick={handleHome}
              style={{
                display: { xs: "none", md: "flex" },
                margin: 10,
                width: 40,
                cursor: "pointer",
              }}
              alt="logo"
            />

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                  maxWidth: "800px",
                }}
              >
                <AnchorMenuItem
                  anchor="/#concours-equestre"
                  label="Concours Equestre"
                  onClick={handleLinkClick}
                />
                <AnchorMenuItem
                  anchor="/#features"
                  label="Fonctionalités"
                  onClick={handleLinkClick}
                />
                <AnchorMenuItem
                  anchor="/#price"
                  label="Tarifs"
                  onClick={handleLinkClick}
                />
                <AnchorMenuItem
                  anchor="/#demos"
                  label="Démo"
                  onClick={handleLinkClick}
                />
                <AnchorMenuItem
                  anchor="/#subscribe"
                  label="Souscrire"
                  onClick={handleLinkClick}
                />
              </Menu>
              <Typography
                variant="h6"
                noWrap
                sx={{
                  m: "auto",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  fontFamily: fontFamily,
                }}
              >
                Concours Equestre
              </Typography>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <AnchorButton
                anchor="/#concours-equestre"
                label="Concours Equestre"
                isFirst={true}
              />
              <AnchorButton anchor="/#features" label="Fonctionalités" />
              <AnchorButton anchor="/#price" label="Tarifs" />
              <AnchorButton anchor="/#demos" label="Démo" />
              <AnchorButton anchor="/#subscribe" label="Souscrire" />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ScrollToColor>
  );
}
