import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Route, Routes } from "react-router";

import CssBaseline from "@material-ui/core/CssBaseline";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { RecoilRoot } from "recoil";
import AppHelmet from "./Components/AppHelmet/AppHelmet";
import ConfidentialityCondition from "./Components/ConfidentialityConditions/ConfidentialityConditions";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Main from "./Components/Main/Main";
import Onboarding from "./Components/Onboarding/Onboarding";
import ScrollToAnchor from "./Components/ScrollToAnchor.js/ScrollToAnchor";
import ServiceCondition from "./Components/ServiceCondition/ServiceCondition";
import Unsubscribe from "./Components/Unsubscribe/Unsubscribe";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#C9B880",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Router>
        <Header />
        <ScrollToAnchor />
        <RecoilRoot>
          <AppHelmet />
          <Routes>
            <Route path="/" exact element={<Main />} />
            <Route
              path="/service-conditions"
              element={<ServiceCondition mt={6} background="#EDE4C7" />}
            />
            <Route
              path="/confidentiality-conditions"
              element={<ConfidentialityCondition />}
            />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/unsubscribe" element={<Unsubscribe />} />
            <Route path="*" element={<Main />} />
          </Routes>
        </RecoilRoot>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
