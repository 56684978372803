import { Box, CssBaseline, Grid, MobileStepper, Paper } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useNavigate } from "react-router";
import Arabesque from "../../assets/arabesque.svg";
import Cover from "../../assets/cover.jpg";
import EmailValidation from "./Steps/01EmailValidation";
import PasswordForm from "./Steps/02Password";
import UserInfo from "./Steps/03UserInfo";
import ClubInfo from "./Steps/04ClubInfo";
import SocialNetworks from "./Steps/05SocialNetwork";
import DomainName from "./Steps/06DomainName";
import StepServiceCondition from "./Steps/07ServiceCondition";
import Resume from "./Steps/08Resume";
import End from "./Steps/09End";

const Onboarding = () => {
  const [footerHeight, setFooterHeight] = useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [onboardingData, setOnboardingData] = useState({});
  const navigate = useNavigate();

  const handleStepValidated = useCallback(
    (obj) => {
      setOnboardingData({ ...onboardingData, ...obj });
      if (activeStep === 8) {
        navigate("/");
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    },
    [onboardingData, activeStep, navigate]
  );

  useEffect(() => {
    const handleResize = () => {
      setFooterHeight(document.getElementById("footer").clientHeight);
    };

    window.addEventListener("resize", handleResize);

    setFooterHeight(document.getElementById("footer").clientHeight);
    setTimeout(() => {
      setFooterHeight(document.getElementById("footer").clientHeight);
    }, 100);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LfDf8wpAAAAAGnwo_pfyU0GmQr9nLGa2AOMNavd">
      <Box
        sx={{
          backgroundImage: `url(${Cover})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: `calc(100vh - ${footerHeight}px + 16px)`,
          width: "100%",
        }}
      >
        <Grid
          container
          component="main"
          sx={{
            height: "100%",
          }}
        >
          <CssBaseline />
          <Grid
            item
            sm={false}
            xs={false}
            md={6}
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              justifyContent: { xs: "center", md: "center" },
              alignItems: "center",
            }}
          >
            <img src={Arabesque} alt="arabesque" height="50%" width="50%" />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{ mt: "70px", mb: "30px", px: 1, pb: 2 }}
          >
            <Paper
              elevation={6}
              square
              sx={{
                opacity: 0.8,
                borderRadius: "10px",
                minHeight: "100%",
                width: "100%",
              }}
            >
              <Box sx={{ px: 2, opacity: 1, height: "100%", margin: "auto" }}>
                <Box sx={{ height: "calc(100% - 50px)" }}>
                  <MobileStepper
                    variant="progress"
                    steps={9}
                    position="static"
                    activeStep={activeStep}
                    sx={{ width: "100%", flexGrow: 1, mt: 2 }}
                    color="success"
                  />
                  {activeStep === 0 && (
                    <EmailValidation onStepValidated={handleStepValidated} />
                  )}
                  {activeStep === 1 && (
                    <PasswordForm onStepValidated={handleStepValidated} />
                  )}
                  {activeStep === 2 && (
                    <UserInfo onStepValidated={handleStepValidated} />
                  )}
                  {activeStep === 3 && (
                    <ClubInfo onStepValidated={handleStepValidated} />
                  )}
                  {activeStep === 4 && (
                    <SocialNetworks onStepValidated={handleStepValidated} />
                  )}
                  {activeStep === 5 && (
                    <DomainName
                      onStepValidated={handleStepValidated}
                      clubName={
                        onboardingData.clubAbbreviation ??
                        onboardingData.clubName
                      }
                    />
                  )}
                  {activeStep === 6 && (
                    <StepServiceCondition
                      onStepValidated={handleStepValidated}
                    />
                  )}
                  {activeStep === 7 && (
                    <Resume
                      onStepValidated={handleStepValidated}
                      onboardingData={onboardingData}
                    />
                  )}
                  {activeStep === 8 && (
                    <End onStepValidated={handleStepValidated} />
                  )}
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </GoogleReCaptchaProvider>
  );
};

export default Onboarding;
