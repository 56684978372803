import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const PasswordForm = ({ onStepValidated }) => {
  const [passwordError, setPasswordError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (event) => {
    setPasswordError(null);
  };

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setPasswordError(null);
    const formData = new FormData(event.currentTarget);

    // Passwords should not be empty
    const password = formData.get("password");
    const confirmPassword = formData.get("confirmPassword");
    if (password === "" || confirmPassword === "") {
      setPasswordError("Veuillez remplir les champs de mot de passe.");
      return;
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError(
        "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule et un chiffre."
      );
      return;
    }

    // Check if password and confirm password match
    if (password !== confirmPassword) {
      // Passwords don't match, show an error message
      setPasswordError("Les mots de passe ne correspondent pas.");
      return;
    }

    onStepValidated({
      password: password,
      passwordConfirmation: confirmPassword,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
        Mot de passe
      </Typography>

      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{ mt: 2, mx: 4, width: "90%" }}
      >
        <Typography component="p" variant="body1" sx={{ mt: 2 }}>
          Veuillez définir un mot de passe pour votre compte (8 caractères
          minimum, 1 majuscule, 1 minuscule, 1 chiffre).
        </Typography>
        <OutlinedInput
          margin="normal"
          required
          fullWidth
          name="password"
          type={showPassword ? "text" : "password"}
          id="password"
          autoComplete="current-password"
          placeholder="Mot de passe *"
          onKeyDown={handlePasswordChange}
          sx={{ mt: 2 }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        <OutlinedInput
          margin="normal"
          required
          fullWidth
          name="confirmPassword"
          type={showPassword ? "text" : "password"}
          id="confirmPassword"
          autoComplete="current-password"
          placeholder="Confirmer le mot de passe *"
          onKeyDown={handlePasswordChange}
          sx={{ mt: 1 }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        {passwordError && (
          <Typography
            component="p"
            variant="body1"
            sx={{ mt: 2, color: "red" }}
          >
            {passwordError}
          </Typography>
        )}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Valider
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PasswordForm;
