import { Box, Button, Typography } from "@mui/material";
import React, { useCallback } from "react";

const End = ({ onStepValidated }) => {
  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      onStepValidated();
    },
    [onStepValidated]
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
        Espace créé !
      </Typography>

      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{ mt: 2, mx: 4, width: "90%" }}
      >
        <Typography component="p" variant="body1" sx={{ mt: 2 }}>
          Votre espace a été créé avec succès !
          <br />
          Le création du nom de domaine sera faite dans les prochaines 24h.
          <br />
          <br />
          Vous recevrez un email de confirmation une fois que votre espace sera
          prêt. Ce mail contiendra un lien pour vous connecter à votre espace
          ainsi que toutes les informations nécessaires pour la prise en main !
        </Typography>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
        >
          J'ai compris !
        </Button>
      </Box>
    </Box>
  );
};

export default End;
