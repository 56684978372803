import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";

import MailIcon from "@mui/icons-material/Mail";
import logo from "../../assets/logo.png";

export default function Footer() {
  const fontFamily = useMemo(() => {
    // if safari : luminary, else : fantasy
    return window.navigator.userAgent.indexOf("Safari") !== -1 &&
      window.navigator.userAgent.indexOf("Chrome") === -1
      ? "Luminari"
      : "fantasy";
  }, []);

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#161616",
      }}
      id="footer"
    >
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <Stack direction="row" spacing={2}>
              <img
                src={logo}
                style={{
                  display: { xs: "none", md: "flex" },
                  width: 40,
                  cursor: "pointer",
                  marginBottom: 10,
                }}
                alt="logo"
              />
              <Typography
                variant="body1"
                sx={{
                  fontFamily: fontFamily,
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Concours Equestre
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <IconButton
              href="mailto:contact@concours-equestre.fr"
              sx={{ pl: 1, pr: 1, color: "#fff" }}
            >
              <MailIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        <Typography
          variant="body2"
          sx={{ textAlign: "center", color: "#cecece", m: 2 }}
        >
          Nous utilisons un système de cookies qui nous permet d'obtenir des
          statistiques de fréquentation sur le site.
          <br />
          Vous pouvez les accepter ou les refuser en cliquant sur le lien :{" "}
          <Link href="#tarteaucitron">Gestion des cookies</Link> et consulter
          notre{" "}
          <Link href="/confidentiality-conditions">
            politique de confidentialité
          </Link>
          .
          <br />
          Veuillez lire nos{" "}
          <Link href="/service-conditions">conditions de service</Link> avant de
          créer l'espace de votre club.
        </Typography>

        <Typography variant="body1" sx={{ color: "#fff", textAlign: "center" }}>
          © {new Date().getFullYear()} VelvetHoof -{" "}
          <Link href="mailto:contact@concours-equestre.fr">
            contact@concours-equestre.fr
          </Link>
        </Typography>
      </Container>
    </Box>
  );
}
