import {
  Box,
  Button,
  FormControl,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const ClubInfo = ({ onStepValidated }) => {
  const [error, setError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [clubAbbrError, setClubAbbrError] = useState(null);
  const [clubNameError, setClubNameError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);

  const handleChange = (event) => {
    setError(null);
    setEmailError(null);
    setClubAbbrError(null);
    setClubNameError(null);
    setPhoneError(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Perform any necessary actions with the club information
    const formData = new FormData(event.currentTarget);
    const clubName = formData.get("clubName");
    if (clubName === "") {
      setError("Veuillez entrer le nom de votre club.");
      setClubNameError(true);
      return;
    }

    const clubAbbreviation = formData.get("clubAbbreviation");
    if (clubAbbreviation.length > 10) {
      setError("Le nom abrégé ne doit pas dépasser 10 caractères.");
      setClubAbbrError(true);
      return;
    }

    const clubLocation = formData.get("clubLocation");

    const clubPhoneNumber = formData.get("clubPhoneNumber");
    const phoneRegex = /^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$/;
    if (clubPhoneNumber !== "" && !phoneRegex.test(clubPhoneNumber)) {
      setError("Veuillez entrer un numéro de téléphone valide.");
      setPhoneError(true);
      return;
    }

    const clubEmail = formData.get("clubEmail");
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(clubEmail)) {
      setError("Veuillez entrer une adresse email valide.");
      setEmailError(true);
      return;
    }

    onStepValidated({
      clubName: clubName,
      clubAbbreviation: clubAbbreviation,
      clubLocation: clubLocation,
      clubPhoneNumber: clubPhoneNumber,
      clubEmail: clubEmail,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
        Informations sur votre club
      </Typography>

      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{ mt: 2, mx: 4, width: "90%" }}
      >
        <FormControl fullWidth>
          <OutlinedInput
            sx={{ mt: 2 }}
            required
            fullWidth
            id="clubName"
            placeholder="Nom du club"
            name="clubName"
            autoComplete="clubName"
            autoFocus
            onChange={handleChange}
            error={clubNameError}
          />
          <Typography variant="caption" sx={{ textAlign: "right" }}>
            * Obligatoire
          </Typography>
        </FormControl>

        <OutlinedInput
          sx={{ mt: 1 }}
          fullWidth
          id="clubAbbreviation"
          placeholder="Nom abrégé du club (10 lettres)"
          name="clubAbbreviation"
          autoComplete="clubAbbreviation"
          onChange={handleChange}
          error={clubAbbrError}
        />

        <OutlinedInput
          sx={{ mt: 1 }}
          fullWidth
          id="clubLocation"
          placeholder="Adresse du club"
          name="clubLocation"
          autoComplete="clubLocation"
          onChange={handleChange}
        />

        <OutlinedInput
          sx={{ mt: 1 }}
          fullWidth
          id="clubPhoneNumber"
          placeholder="Numéro de téléphone"
          name="clubPhoneNumber"
          autoComplete="clubPhoneNumber"
          onChange={handleChange}
          error={phoneError}
        />

        <FormControl fullWidth>
          <OutlinedInput
            sx={{ mt: 1 }}
            fullWidth
            id="clubEmail"
            placeholder="Adresse email du club"
            name="clubEmail"
            autoComplete="clubEmail"
            onChange={handleChange}
            required
            error={emailError}
          />
          <Typography variant="caption" sx={{ textAlign: "right" }}>
            * Obligatoire
          </Typography>
        </FormControl>
        {error && (
          <Typography
            component="p"
            variant="body1"
            sx={{ mt: 2, color: "red" }}
            fullWidth
          >
            {error}
          </Typography>
        )}
        <Button fullWidth variant="contained" sx={{ mt: 2 }} type="submit">
          Suivant
        </Button>
      </Box>
    </Box>
  );
};

export default ClubInfo;
