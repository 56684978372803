import { Box, Button, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";

import { useRecoilState } from "recoil";
import { originAtom } from "../../../Features/atom";

const Resume = ({ onStepValidated, onboardingData }) => {
  const { email, clubName, domainName } = onboardingData;
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [atom] = useRecoilState(originAtom);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const postData = {
        Name: clubName,
        Address: onboardingData.clubLocation,
        PhoneNumber: onboardingData.clubPhoneNumber,
        Email: onboardingData.clubEmail,
        ShortName: onboardingData.clubAbbreviation,
        Domain: domainName,
        User: {
          Email: email,
          FirstName: onboardingData.firstName,
          LastName: onboardingData.lastName,
          Password: onboardingData.password,
          PasswordConfirmation: onboardingData.passwordConfirmation,
        },
        SocialNetwork: {
          Website: onboardingData.website,
          Facebook: onboardingData.facebook,
          Twitter: onboardingData.twitter,
          Instagram: onboardingData.instagram,
        },
        validationCode: onboardingData.validationCode,
      };

      try {
        setIsLoading(true);
        setError(null);

        try {
          window.gtag("event", `purchase`, {
            origin: atom,
          });
        } catch (gta_error) {}

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/subscribe/finalize`,
          {
            method: "POST",
            body: JSON.stringify(postData),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status !== 200) {
          setError(
            "Une erreur s'est produite lors de la création de l'espace club. Veuillez réessayer plus tard."
          );
          return;
        }
        onStepValidated();
      } catch (error) {
        setError(
          "Une erreur s'est produite lors de la création de l'espace club. Veuillez réessayer plus tard."
        );
      } finally {
        setIsLoading(false);
      }
    },
    [
      atom,
      clubName,
      domainName,
      email,
      onStepValidated,
      onboardingData.clubAbbreviation,
      onboardingData.clubEmail,
      onboardingData.clubLocation,
      onboardingData.clubPhoneNumber,
      onboardingData.facebook,
      onboardingData.firstName,
      onboardingData.instagram,
      onboardingData.lastName,
      onboardingData.password,
      onboardingData.passwordConfirmation,
      onboardingData.twitter,
      onboardingData.validationCode,
      onboardingData.website,
    ]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
        Tout est prêt !
      </Typography>

      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{ mt: 2, mx: 4, width: "90%" }}
      >
        <Typography component="p" variant="body1" sx={{ mt: 2 }}>
          Nous avons bien reçu toutes les informations nécessaires pour la
          création de votre espace club.
        </Typography>
        <Typography component="p" variant="body1" sx={{ mt: 2 }}>
          Compte administrateur : {email}
        </Typography>
        <Typography component="p" variant="body1" sx={{ mt: 1 }}>
          Nom du club : {clubName}
        </Typography>
        <Typography component="p" variant="body1" sx={{ mt: 1 }}>
          Nom de domaine : {domainName}
        </Typography>
        {error && (
          <Typography
            component="p"
            variant="body1"
            sx={{ mt: 2, color: "red" }}
            fullWidth
          >
            {error}
          </Typography>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
          disabled={isLoading}
        >
          Créer mon espace club
        </Button>
      </Box>
    </Box>
  );
};

export default Resume;
