import { Box, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";

import Arabesque from "../../assets/arabesque.svg";
import Cover from "../../assets/cover.jpg";
import { Grid } from "@mui/material";

export default function SplashScreen() {
  const fontFamily = useMemo(() => {
    // if safari : luminary, else : fantasy
    return window.navigator.userAgent.indexOf("Safari") !== -1 &&
      window.navigator.userAgent.indexOf("Chrome") === -1
      ? "Luminari"
      : "fantasy";
  }, []);

  return (
    <Box
      style={{
        height: "360px",
        backgroundImage: `url(${Cover})`,
        width: "100%",
        position: "relative",
        top: "0px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        marginBottom: "0px",
      }}
    >
      <Grid container sx={{ maxHeight: "360px" }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            height: "360px",
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <img src={Arabesque} alt="arabesque" height="70%" />
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            height: "360px",
            justifyContent: "flex-start",
            alignItems: "center",
            display: { xs: "none", md: "flex", marginTop: "30px" },
          }}
        >
          <Stack>
            <Typography
              sx={{
                color: "#fff",
                fontFamily: fontFamily,
                fontSize: { xl: "72pt", md: "48pt", sm: "24pt" },
                lineHeight: { xl: "68pt", md: "38pt", sm: "24pt" },
              }}
            >
              Concours
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontFamily: fontFamily,
                fontSize: { xl: "68pt", md: "40pt", sm: "18pt" },
                lineHeight: { xl: "68pt", md: "38pt", sm: "24pt" },
              }}
            >
              Equestre
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
