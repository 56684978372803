import { Button, Link } from "@mui/material";
import React, { useCallback } from "react";

import { useNavigate } from "react-router";

export default function AnchorButton({ anchor, label, isFirst }) {
  const navigate = useNavigate();
  const handleLinkClick = useCallback(
    (event) => {
      event.preventDefault();
      navigate(anchor);
    },
    [anchor, navigate]
  );

  return (
    <Button
      sx={{
        my: 2,
        display: "block",
        ml: isFirst ? 0 : 2,
        mr: 3,
      }}
      onClick={handleLinkClick}
    >
      <Link
        href={anchor}
        style={{
          color: "rgb(34, 44, 54)",
          textDecoration: "none",
          fontWeight: "500",
          fontSize: "16px",
          textTransform: "none",
        }}
      >
        {label}
      </Link>
    </Button>
  );
}
