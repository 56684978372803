import { Box, Button, OutlinedInput, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";

import FacebookIcon from "@mui/icons-material/Facebook";
import HttpIcon from "@mui/icons-material/Http";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";

const SocialNetworks = ({ onStepValidated }) => {
  const [error, setError] = useState(null);
  const [socialNetworksError, setSocialNetworksError] = useState({
    facebook: null,
    twitter: null,
    instagram: null,
    www: null,
  });

  const handleChange = (e) => {
    const { name } = e.target;
    setSocialNetworksError({ ...socialNetworksError, [name]: null });
    setError(null);
  };

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const facebook = formData.get("facebook");
      const twitter = formData.get("twitter");
      const instagram = formData.get("instagram");
      const www = formData.get("www");

      // Check if the URLs are valid
      const urlRegex =
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
      if (facebook !== "" && !urlRegex.test(facebook)) {
        setSocialNetworksError({ ...socialNetworksError, facebook: true });
        setError("Veuillez entrer une URL valide pour Facebook.");
        return;
      }
      if (twitter !== "" && !urlRegex.test(twitter)) {
        setSocialNetworksError({ ...socialNetworksError, twitter: true });
        setError("Veuillez entrer une URL valide pour Twitter.");
        return;
      }
      if (instagram !== "" && !urlRegex.test(instagram)) {
        setSocialNetworksError({ ...socialNetworksError, instagram: true });
        setError("Veuillez entrer une URL valide pour Instagram.");
        return;
      }
      if (www !== "" && !urlRegex.test(www)) {
        setSocialNetworksError({ ...socialNetworksError, www: true });
        setError("Veuillez entrer une URL valide pour le site web.");
        return;
      }

      onStepValidated({
        facebook: facebook,
        twitter: twitter,
        instagram: instagram,
        website: www,
      });
    },
    [onStepValidated, socialNetworksError]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
        Réseaux sociaux
      </Typography>

      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{ mt: 2, mx: 4, width: "90%" }}
      >
        <Typography component="p" variant="body1" sx={{ mt: 2 }}>
          Vous pouvez renseigner les réseaux sociaux de votre club pour que les
          membres puissent vous suivre.
          <br />
          Toutes ces informations sont optionnelles.
        </Typography>
        <OutlinedInput
          margin="normal"
          fullWidth
          id="facebook"
          placeholder="Facebook"
          name="facebook"
          autoComplete="facebook"
          autoFocus
          onChange={handleChange}
          error={socialNetworksError.facebook}
          sx={{ mt: 2 }}
          startAdornment={<FacebookIcon sx={{ mr: 1 }} />}
        />
        <OutlinedInput
          margin="normal"
          fullWidth
          id="twitter"
          placeholder="Twitter"
          name="twitter"
          autoComplete="twitter"
          onChange={handleChange}
          error={socialNetworksError.twitter}
          sx={{ mt: 1 }}
          startAdornment={<TwitterIcon sx={{ mr: 1 }} />}
        />
        <OutlinedInput
          margin="normal"
          fullWidth
          id="instagram"
          placeholder="Instagram"
          name="instagram"
          autoComplete="instagram"
          onChange={handleChange}
          error={socialNetworksError.instagram}
          sx={{ mt: 1 }}
          startAdornment={<InstagramIcon sx={{ mr: 1 }} />}
        />
        <OutlinedInput
          margin="normal"
          fullWidth
          id="www"
          placeholder="Site web"
          name="www"
          autoComplete="www"
          onChange={handleChange}
          error={socialNetworksError.www}
          sx={{ mt: 1 }}
          startAdornment={<HttpIcon sx={{ mr: 1 }} />}
        />
        {error && (
          <Typography
            component="p"
            variant="body1"
            sx={{ mt: 2, color: "red" }}
            fullWidth
          >
            {error}
          </Typography>
        )}
        <Button fullWidth variant="contained" sx={{ mt: 2 }} type="submit">
          Suivant
        </Button>
      </Box>
    </Box>
  );
};

export default SocialNetworks;
