import { Box, Button, OutlinedInput, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";

const UserInfo = ({ onStepValidated }) => {
  const [error, setError] = useState(null);

  const handleFirstNameChange = (e) => {
    setError(null);
  };

  const handleLastNameChange = (e) => {
    setError(null);
  };

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setError(null);
      const formData = new FormData(event.currentTarget);

      // First name and last name should not be empty
      const firstName = formData.get("firstName");
      const lastName = formData.get("lastName");
      if (firstName === "" || lastName === "") {
        setError("Veuillez remplir les champs de prénom et nom.");
        return;
      }

      onStepValidated({
        firstName: firstName,
        lastName: lastName,
      });
    },
    [onStepValidated]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
        Dites-nous en un peu plus sur vous !
      </Typography>

      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{ mt: 2, mx: 4, width: "90%" }}
      >
        <OutlinedInput
          sx={{ mt: 2 }}
          required
          fullWidth
          id="firstName"
          placeholder="Prénom"
          name="firstName"
          autoComplete="given-name"
          autoFocus
          onKeyDown={handleFirstNameChange}
        />
        <OutlinedInput
          sx={{ mt: 1 }}
          required
          fullWidth
          id="lastName"
          placeholder="Nom"
          name="lastName"
          autoComplete="family-name"
          onKeyDown={handleLastNameChange}
        />
        {error && (
          <Typography
            component="p"
            variant="body1"
            sx={{ mt: 2, color: "red" }}
            fullWidth
          >
            {error}
          </Typography>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
        >
          Suivant
        </Button>
      </Box>
    </Box>
  );
};

export default UserInfo;
