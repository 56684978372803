import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

export default function Demos() {
  const [selected, setSelected] = useState("1");
  const videoRef = useRef();

  const items = useMemo(
    () => [
      {
        value: "1",
        label: "Créer un concours",
        video: "https://d2l0r94ndumlx0.cloudfront.net/videos/demo01.mp4",
      },
      {
        value: "2",
        label: "S’inscrire à un concours",
        video: "https://d2l0r94ndumlx0.cloudfront.net/videos/demo02.mp4",
      },
      {
        value: "3",
        label: "Gérer les inscriptions",
        video: "https://d2l0r94ndumlx0.cloudfront.net/videos/demo03.mp4",
      },
      {
        value: "4",
        label: "Voir mes inscriptions",
        video: "https://d2l0r94ndumlx0.cloudfront.net/videos/demo04.mp4",
      },
      {
        value: "8",
        label: "Définir le moyen de paiement des utilisateurs",
        video: "https://d2l0r94ndumlx0.cloudfront.net/videos/demo08.mp4",
      },
      {
        value: "5",
        label: "Administration: gérer les utilisateurs",
        video: "https://d2l0r94ndumlx0.cloudfront.net/videos/demo05.mp4",
      },
      {
        value: "6",
        label: "Administration: gérer les catégories de concours",
        video: "https://d2l0r94ndumlx0.cloudfront.net/videos/demo06.mp4",
      },
      {
        value: "7",
        label: "Administration: personnalisation du site",
        video: "https://d2l0r94ndumlx0.cloudfront.net/videos/demo07.mp4",
      },
      {
        value: "9",
        label: "Administration: définir le moyen de paiement du club",
        video: "https://d2l0r94ndumlx0.cloudfront.net/videos/demo09.mp4",
      },
      {
        value: "10",
        label: "Administration: facturation",
        video: "https://d2l0r94ndumlx0.cloudfront.net/videos/demo10.mp4",
      },
    ],
    []
  );

  const video = useMemo(() => {
    const item = items.find((item) => item.value === selected);
    return item?.video;
  }, [items, selected]);

  const handleSelect = useCallback((event) => {
    setSelected(event.target.value);
  }, []);

  useEffect(() => {
    videoRef.current?.load();
  }, [video]);

  return (
    <Box id="demos" sx={{ backgroundColor: "#302818", py: 4 }}>
      <Container maxWidth="lg">
        <Stack>
          <Typography variant="h4" sx={{ color: "#fff" }}>
            Démo
          </Typography>
        </Stack>
        <Grid container spacing={4} sx={{ pt: 2 }}>
          <Grid
            item
            md={4}
            lg={3}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <List sx={{ color: "#FFF" }}>
              {items.map((item) => (
                <ListItem
                  key={item.value}
                  value={item.value}
                  sx={{
                    fontWeight: selected === item.value ? "bold" : "inherit",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelected(item.value)}
                >
                  {item.label}
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <Select
              fullWidth
              value={selected}
              sx={{ color: "#FFF" }}
              onChange={handleSelect}
            >
              {items.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9}>
            <video width="100%" controls ref={videoRef}>
              <source src={video} />
              Your browser does not support HTML video.
            </video>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
