import { Box, Button, OutlinedInput, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const EmailValidation = ({ onStepValidated }) => {
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleEmailChange = (e) => {
    setIsCodeSent(false);
    setIsError(null);
  };

  const handleValidationCodeChange = (e) => {
    setIsError(null);
  };

  const sendValidationCode = useCallback(
    async (e) => {
      const formData = new FormData(e.currentTarget);
      const email = formData.get("email");

      // Is the email valid?
      const regexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!regexp.test(email)) {
        setIsError("Veuillez entrer une adresse email valide.");
        return;
      }

      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        setIsError("Une erreur est survenue, veuillez réessayer plus tard.");
        return;
      }

      const token = await executeRecaptcha("validate_email");

      // Ask for verification code in the API on url REACT_APP_API_URL
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/account/validate-email`,
        {
          method: "POST",
          body: JSON.stringify({
            Email: email,
            Captcha: token,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status !== 200) {
        setIsError("Une erreur est survenue, veuillez réessayer plus tard.");
        return;
      } else {
        setIsCodeSent(true);
      }
    },
    [executeRecaptcha]
  );

  const validateCode = useCallback(
    async (e) => {
      const formData = new FormData(e.currentTarget);
      const email = formData.get("email");
      const validationCode = formData.get("validationCode");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/account/validate-email-code`,
        {
          method: "POST",
          body: JSON.stringify({
            Email: email,
            Captcha: validationCode,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status !== 200) {
        setIsError("Le code de validation est incorrect.");
        return;
      } else {
        onStepValidated({ email, validationCode });
      }
    },
    [onStepValidated]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        setIsLoading(true);
        setIsError(null);

        if (!isCodeSent) {
          await sendValidationCode(e);
        } else {
          await validateCode(e);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [isCodeSent, sendValidationCode, validateCode]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
        Informations de connexion
      </Typography>

      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{ mt: 2, mx: 4, width: "90%" }}
      >
        <Typography component="p" variant="body1" sx={{ mt: 2 }}>
          Veuillez entrer votre adresse e-mail, celle qui sera utilisée pour
          vous connecter à votre compte une fois votre espace créé.
        </Typography>
        <OutlinedInput
          sx={{ my: 2 }}
          required
          fullWidth
          id="email"
          placeholder="Adresse email"
          name="email"
          autoComplete="email"
          autoFocus
          onKeyDown={handleEmailChange}
        />
        {isCodeSent && (
          <>
            <Typography component="p" variant="body1" sx={{ mt: 2 }}>
              Un code de validation vous a été envoyé par email. Veuillez le
              saisir ci-dessous.
            </Typography>
            <OutlinedInput
              sx={{ my: 2 }}
              required
              fullWidth
              id="validationCode"
              placeholder="Code de validation"
              name="validationCode"
              autoComplete="validationCode"
              autoFocus
              onKeyDown={handleValidationCodeChange}
            />
          </>
        )}
        {isError && (
          <Typography
            component="p"
            variant="body1"
            sx={{ mt: 2, color: "red" }}
          >
            {isError}
          </Typography>
        )}

        <Button
          fullWidth
          variant="contained"
          disabled={isLoading}
          sx={{ mt: 2 }}
          type="submit"
        >
          {!isCodeSent ? "Envoyer le code de validation" : "Valider"}
        </Button>
      </Box>
    </Box>
  );
};

export default EmailValidation;
