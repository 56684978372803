import { Link, MenuItem } from "@mui/material";
import React, { useCallback } from "react";

import { useNavigate } from "react-router";

export default function AnchorMenuItem({ anchor, label, onClick }) {
  const navigate = useNavigate();
  const handleLinkClick = useCallback(
    (event) => {
      event.preventDefault();
      navigate(anchor);
    },
    [anchor, navigate]
  );

  return (
    <MenuItem
      onClick={handleLinkClick}
      selected={anchor === window.location.pathname}
    >
      <Link
        href={anchor}
        style={{
          textDecoration: "none",
          color: "#000",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
      >
        {label}
      </Link>
    </MenuItem>
  );
}
