import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Helmet } from "react-helmet";
import { useRecoilState } from "recoil";
import { originAtom } from "../../Features/atom";

const AppHelmet = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [detectOrigin, setDetectOrigin] = useState(null);
  const [atom, setAtom] = useRecoilState(originAtom); // eslint-disable-line no-unused-vars

  // If the URL contains origin, push it to the dataLayer, using useEffect
  useEffect(() => {
    const origin = searchParams.get("origin");
    if (origin) {
      setDetectOrigin(origin);
      setAtom(origin);
      console.log("origin", origin);

      // remove the query from url
      searchParams.delete("origin");
      navigate("?" + searchParams.toString(), { replace: true });
    }
  }, [searchParams, navigate, setAtom]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (detectOrigin && window.gtag) {
        console.log("detectOrigin", detectOrigin);
        clearInterval(interval);
        window.gtag("event", `origin`, {
          value: detectOrigin,
        });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [detectOrigin]);

  return <Helmet></Helmet>;
};

export default AppHelmet;
