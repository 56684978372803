import { Box, Container, Grid, Stack, Typography } from "@mui/material";

import React from "react";
import Me from "../../assets/me.jpg";

export default function Intro() {
  return (
    <Box id="concours-equestre" sx={{ backgroundColor: "#302818", py: 4 }}>
      <Container maxWidth="lg">
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={7} lg={8}>
            <Stack>
              <Typography variant="h4" sx={{ color: "#fff" }}>
                Gérer les inscriptions à vos concours équestres, simplement.
              </Typography>
              <Typography
                variant="body"
                sx={{
                  color: "#fff",
                  pt: 2,
                  lineHeight: "20pt",
                  fontStyle: "italic",
                  fontSize: "1rem",
                }}
              >
                &#171; Cavalier, papa d'une cavalière, j'ai créé la première
                version de <b>Concours Equestre</b> à la demande de mon club
                pour gérer les inscriptions à ses concours. Avant cela, ils
                utlisaient des liens Google Forms, un groupe WhatsApp, et
                devaient ensuite traiter les inscriptions manuellement. Pour les
                cavaliers, c'était un peu la galère aussi : il fallait retrouver
                le lien des différents formulaires, se rappeler des dates, etc.
                &#187;
                <br />
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#cecece",
                  fontStyle: "italic",
                  pl: 2,
                  pt: 1,
                }}
              >
                {"     "}
                Etienne, fondateur de VelvetHoof
              </Typography>
              <Typography
                variant="body"
                sx={{
                  color: "#fff",
                  pt: 2,
                  lineHeight: "20pt",
                  fontWeight: 700,
                  fontSize: "1rem",
                }}
              >
                Avec Concours Equestre, tout est centralisé. C'est plus simple
                pour les clubs et plus pratique pour les cavaliers !
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              maxHeight: "500px",
            }}
          >
            <img
              src={Me}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                width: "auto",
                height: "auto",
              }}
              alt="landing"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
