import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";

const DomainName = ({ onStepValidated, clubName }) => {
  const [error, setError] = useState(null);
  const [domainType, setDomainType] = useState("subdomain");
  const [domainNameError, setDomainNameError] = useState(null);
  const [domainName, setDomainName] = useState(clubName?.toLowerCase());
  const [isLoading, setIsLoading] = useState(false);

  const handleDomainTypeChange = useCallback((event) => {
    setDomainType(event.target.value);
  }, []);

  const handleChange = (e) => {
    setError(null);
    setDomainNameError(null);
  };

  const handleSubDomainChange = (e) => {
    setError(null);
    setDomainNameError(null);
    const domainName = e.target.value;
    setDomainName(e.target.value);

    // Check if the domain name is valid
    const domainNameRegex = /^[a-z0-9-]+$/;
    if (!domainNameRegex.test(domainName)) {
      setDomainNameError(true);
      setError(
        "Le nom de domaine ne doit contenir que des lettres minuscules, des chiffres et des tirets."
      );
      return;
    }
  };

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setError(null);
      const formData = new FormData(event.currentTarget);

      // Domain name should not be empty
      const domainName = formData.get("domainName");
      if (domainName === "") {
        setError("Veuillez choisir un nom de domaine.");
        return;
      }

      // Perform any necessary actions with the domain name
      const domain = formData.get("domainName");
      let fullDomainName;

      if (domainType === "subdomain") {
        fullDomainName = `${domain}.concours-equestre.fr`;
      } else {
        // Remove http:// or https:// from the domain name
        const domainRegex = /^(https?:\/\/)?(.*)$/;
        const match = domain.match(domainRegex);
        if (match) {
          fullDomainName = match[2];
        } else {
          fullDomainName = domain;
        }
      }

      try {
        setIsLoading(true);
        // Perform any necessary actions with the domain name
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/subscribe/domain-exist?domain=${fullDomainName}`
        );

        if (response.status !== 404) {
          setError("Ce nom de domaine est déjà utilisé.");
          return;
        }
        onStepValidated({ domainName: fullDomainName });
      } finally {
        setIsLoading(false);
      }
    },
    [domainType, onStepValidated]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
        Nom de domaine
      </Typography>

      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{ mt: 2, mx: 4, width: "90%" }}
      >
        <Typography component="p" variant="body1" sx={{ mt: 2 }}>
          Veuillez choisir un nom de domaine pour votre site de gestion de
          concours équestres.
        </Typography>

        <FormControl component="fieldset" sx={{ mt: 2 }}>
          <FormLabel component="legend">Type de domaine</FormLabel>
          <RadioGroup
            aria-label="domainType"
            name="domainType"
            value={domainType}
            onChange={handleDomainTypeChange}
          >
            <FormControlLabel
              value="subdomain"
              control={<Radio />}
              label="Sous-domaine de concours-equestre.fr"
            />
            <FormControlLabel
              value="custom"
              control={<Radio />}
              label="Nom de domaine personnalisé"
            />
          </RadioGroup>
        </FormControl>
        {domainType === "custom" && (
          <Box>
            <Typography component="p" variant="caption" sx={{ mt: 2 }}>
              Entrez le nom de domaine personnalisé que vous souhaitez utiliser.
              Nous vous contacterons pour aider à la configuration.
              <br /> Vous devez posséder le nom de domaine et pouvoir modifier
              les enregistrements DNS.
              <br />
              La mise en place du nom de domaine personnalisé est un service
              payant (15€).
            </Typography>
            <OutlinedInput
              margin="normal"
              required
              fullWidth
              id="domainName"
              placeholder="Nom de domaine"
              name="domainName"
              autoComplete="domainName"
              autoFocus
              onChange={handleChange}
              error={domainNameError}
              sx={{ mt: 2 }}
            />
          </Box>
        )}
        {domainType === "subdomain" && (
          <>
            <OutlinedInput
              margin="normal"
              required
              fullWidth
              id="domainName"
              placeholder="Nom de sous-domaine"
              name="domainName"
              autoComplete="domainName"
              autoFocus
              onChange={handleSubDomainChange}
              error={domainNameError}
              sx={{ mt: 2 }}
              value={domainName}
            />
            <Typography component="p" variant="caption" sx={{ mt: 2 }}>
              Votre site sera accessible à l'adresse suivante :
              <br />
              <strong>{`https://${domainName}.concours-equestre.fr`}</strong>
            </Typography>
          </>
        )}
        {error && (
          <Typography
            component="p"
            variant="body1"
            sx={{ mt: 2, color: "red" }}
            fullWidth
          >
            {error}
          </Typography>
        )}
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 2 }}
          type="submit"
          disabled={error !== null || domainNameError !== null || isLoading}
        >
          Suivant
        </Button>
      </Box>
    </Box>
  );
};

export default DomainName;
