import { useScrollTrigger } from "@mui/material";
import React from "react";

const ScrollHandler = (props) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: props.threshold ? props.threshold : 0,
    target: props.window ? window() : undefined,
  });

  return React.cloneElement(props.children, {
    style: {
      backgroundColor: trigger || !props.threshold ? "#EDE4C7" : "transparent",
      transition: trigger ? "0.3s" : "0.5s",
      padding: "0px 0px",
      boxShadow: trigger ? "0px 0px 10px 0px rgba(0,0,0,0.2)" : "none",
    },
  });
};

const ScrollToColor = (props) => {
  return <ScrollHandler {...props}>{props.children}</ScrollHandler>;
};

export default ScrollToColor;
