import React from "react";
import Demos from "../Demos/Demos";
import Features from "../Features/Features";
import Intro from "../Intro/Intro";
import Prices from "../Prices/Prices";
import SplashScreen from "../SplashScreen/SplashScreen";
import Subscribe from "../Subscribe/Subscribe";

export default function Main() {
  return (
    <>
      <SplashScreen />
      <Intro />
      <Features />
      <Prices />
      <Demos />
      <Subscribe />
    </>
  );
}
